<template>
  <div>
    <PageHeader :key="globalLanguage" :items="items" />
    <div>
      <div class="card shadow">
        <div class="row px-4">
          <div class="col-12 col-lg-4 pb-2">
            <label class="form-label fw-bold">{{ $t("ID") }}:</label>
            <p>{{ this.$route.params.id }}</p>
          </div>
          <div class="col-12 col-lg-4 pb-2">
            <label class="form-label fw-bold">{{ $t("Name") }}:</label>
            <p>{{ this.name }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex mb-1 gap-1">
        <loading-button
          @click="generatePdf('pdf')"
          :loading="isLoading"
          class="btn btn-secondary mt-1 me-1"
          style="width: 160px"
          >{{ $t("Generate PDF") }}</loading-button
        >
        <loading-button
          @click="generatePdf('docx')"
          :loading="isLoading"
          class="btn btn-secondary mt-1 me-1"
          style="width: 160px"
          >{{ $t("Generate Docx") }}</loading-button
        >
        <loading-button
          @click="downloadOriginalFile"
          :loading="isLoading"
          class="btn btn-secondary mt-1 me-1"
          style="width: 160px"
          >{{ $t("Download Original") }}</loading-button
        >
        <loading-button
          v-if="$can(`${$route.meta.permission}.edit`)"
          @click="$router.push(`/document-templates/${$route.params.id}/edit`)"
          :loading="isLoading"
          class="btn btn-secondary mt-1"
          style="width: 160px"
          >{{ $t("Edit Document") }}</loading-button
        >
      </div>
      <b-row>
        <b-col cols="5" class="pb-5">
          <div class="card shadow">
            <img id="document-preview-image" />
          </div>
        </b-col>
        <b-col cols="7">
          <div class="mb-5">
            <label class="form-label fw-bold mr-1" for="json-input">{{
              $t("JSON")
            }}</label>
            <input
              @input="fetchListings"
              id="json-input"
              type="checkbox"
              class="ms-2"
              v-model="jsonInput"
            />
          </div>
          <div v-if="!jsonInput">
            <div class="row g-2">
              <div class="col-4">
                <label class="fw-bold" for="">Key:</label>
              </div>
              <div class="col-4">
                <label class="fw-bold" for="">Value:</label>
              </div>
              <div class="col-4"></div>
            </div>
            <div
              v-for="(variable, index) in variables"
              :key="'variable-' + index"
              class="row g-2 py-2"
            >
              <div class="col-4">
                <TextInput v-model="variable.key" :isReadonly="true" />
              </div>
              <div class="col-4">
                <TextInput v-model="variable.value" />
              </div>
              <div class="col-4"></div>
            </div>
          </div>
          <div class="mt-5" v-else>
            <div class="row">
              <div class="col-12 col-lg-6 pb-3">
                <MultiSelectInput
                  @input="createJson('user', $event)"
                  v-model="form.user"
                  :key="form.user"
                  :options="users || []"
                  :multiple="false"
                  :text-label="$t('User')"
                  :custom-label="userLabel"
                  trackBy="id"
                  moduleName="users"
                />
              </div>
              <div class="col-12 col-lg-6 pb-3">
                <MultiSelectInput
                  @input="createJson('invoice', $event)"
                  v-model="form.invoice"
                  :key="form.invoice"
                  :options="invoices || []"
                  :multiple="false"
                  :text-label="$t('Invoice')"
                  label="invoiceNumber"
                  trackBy="id"
                  moduleName="invoices"
                />
              </div>
            </div>
            <label class="form-label fw-bold" for="">JSON:</label>
            <MonacoEditor
              @contentChange="json = $event"
              :readOnly="false"
              :codeString="codeString"
              :height="'1000px'"
              :language="'json'"
              class="mt-2"
            />
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import { mapGetters } from "vuex";
import MultiSelectInput from "@/components/MultiSelectInput.vue";
import MonacoEditor from "@/components/MonacoEditor.vue";
import TextInput from "@/components/TextInput.vue";
import LoadingButton from "@/components/LoadingButton.vue";

export default {
  computed: {
    ...mapGetters(["isLoading", "showLoader"]),
    ...mapGetters("users", ["users"]),
    ...mapGetters("invoices", ["invoices"]),
    items() {
      return [
        {
          text: "Dental Twin",
          to: "/home",
        },
        {
          text: this.$t("Settings"),
          to: "/settings",
        },
        {
          text: this.$t("Document Templates"),
          to: "/document-templates",
        },
        {
          text: this.$t("show"),
          active: true,
        },
      ];
    },
  },
  components: {
    TextInput,
    MonacoEditor,
    LoadingButton,
    MultiSelectInput,
    PageHeader,
  },
  data() {
    return {
      name: "",
      form: {
        user: "",
        invoice: "",
        offer: "",
        performanceRecord: "",
      },
      image: "",
      variables: [],
      codeString: "",
      json: "",
      jsonInput: false,
      user: "",
      performanceRecord: "",
      invoice: "",
      offer: "",
    };
  },
  methods: {
    userLabel(user) {
      return user.first_name + " " + user.last_name;
    },
    /**
     * creates json based on the selected user, invoice, offer, performance record and sets it to the editor
     * @param {type} the module name which will be used to fetch the complete resource data using show API
     * @param event the input event
     */
    async createJson(type, event) {
      await this.$nextTick();
      try {
        let response = null;
        if (type === "user") {
          this.user = this.form.user ?? "";
        } else if (type === "invoice") {
          if (event)
            response = await this.$store.dispatch(
              "invoices/show",
              this.form.invoice?.id
            );
          this.invoice = response?.data?.data ?? "";
        }
      } catch (e) {
        console.log(e);
      }
      let json = {};
      try {
        json = JSON.parse(this.json);
      } catch (e) {
      } finally {
        json["user"] = this.user;
        json["invoice"] = this.invoice;
        this.codeString = JSON.stringify(json);
      }
    },
    /**
     * fetch the listing of user profile, invoice, offer and performance record if not already fetched
     */
    async fetchListings() {
      try {
        if (!this.users?.length) await this.$store.dispatch("users/list");
        if (!this.invoices?.data?.length)
          await this.$store.dispatch("invoices/list");
      } catch (e) {
        console.log(e);
      }
    },
    // calls the download orignal file API from document service and downloads the file
    async downloadOriginalFile() {
      try {
        this.$store.commit("isLoading", true);
        const response = await this.$store.dispatch(
          "documentService/downloadOriginalFile",
          {
            id: this.$route.params.id,
            documentType: "docx",
            filename: this.name,
          }
        );
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Adds all the variables(key,value) to the payload and makes an API request
     */
    async generatePdf(documentType) {
      let variables = {};
      if (this.jsonInput) {
        try {
          localStorage.setItem(this.$route.params.id, this.json);
          variables = JSON.parse(this.json);
        } catch (e) {
          return;
        }
      }
      try {
        const payload = {
          id: this.$route.params.id,
          output: documentType,
        };
        if (this.jsonInput) {
          for (let key in variables) {
            payload[key] = this.parseJson(variables[key]);
          }
        } else {
          this.variables.forEach((variable) => {
            payload[variable.key] = this.parseJson(variable.value);
          });
        }
        this.$store.commit("isLoading", true);
        const filename =
          (this.name.match(/^(.*?)\.docx|^(.*?)\.pdf/)?.[1] ?? this.name) +
          "." +
          documentType;
        await this.$store.dispatch("documentService/processTemplate", {
          data: payload,
          filename: filename,
          documentType: documentType,
        });
      } catch (e) {
        console.log(e);
      }
    },
    /**
     * Parses the string to JSON and returns it, if the string cannot be parsed to JSON the original value is returned
     * @param {value} the string to be parsed to JSON
     */
    parseJson(value) {
      try {
        return JSON.parse(value);
      } catch (e) {
        return value;
      }
    },
  },
  async mounted() {
    try {
      this.$store.commit("showLoader", true);
      const documentResponse = await this.$store.dispatch(
        "documentService/show",
        this.$route.params.id
      );
      this.name = documentResponse?.data?.name;
      const response = await this.$store.dispatch(
        "documentService/documentPreviewFiles",
        this.$route.params.id
      );
      this.$store
        .dispatch("documentService/variables", {
          id: this.$route.params.id,
        })
        .then((res) => {
          if (res?.data instanceof Array) {
            this.variables = res.data.map((variable) => {
              return {
                key: variable,
                value: "",
              };
            });
          }
        });
      var reader = new window.FileReader();
      reader.readAsDataURL(response.data);
      reader.onload = function () {
        const imageElement = document.getElementById("document-preview-image");
        var imageDataUrl = reader.result;
        imageElement.setAttribute("src", imageDataUrl);
      };

      const jsonData = localStorage.getItem(this.$route.params.id);
      if (jsonData) {
        this.jsonInput = true;
        this.codeString = jsonData;
        this.json = jsonData;
        const jsonIntoObject = JSON.parse(this.codeString);
        await this.fetchListings();
        if (jsonIntoObject.user) {
          this.users.map((up) => {
            if (up.id === jsonIntoObject.user.id) {
              this.form.user = up;
              return;
            }
          });
        }
        if (jsonIntoObject.invoice) {
          this.invoices.map((invoice) => {
            if (invoice.id === jsonIntoObject.invoice.id) {
              this.form.invoice = invoice;
              return;
            }
          });
        }
      }
    } catch (e) {
      console.log(e);
    } finally {
      this.$store.commit("showLoader", false);
    }
  },
};
</script>
